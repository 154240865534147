import React from "react";
import "./Aboutus.css";
import aboutus from "../assets/aboutus.jpg";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <img src={aboutus} alt="About Us" className="about-us-image" />
    </div>
  );
};

export default AboutUs;