import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./UserDetails.css";

const UserDetail = () => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState(null);
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await axios.get(`/api/v1/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setUserDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (authToken) {
      fetchUserDetails();
    } else {
      history.push("/account");
    }
  }, [authToken, history]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    history.push("/account");
  };

  if (!userDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="user-detail-page">
      <h2>Welcome, {userDetails.email}</h2>
      <p>Email: {userDetails.email}</p>
      <p>Phone Number: {userDetails.phone_number}</p> {/* Corrected field */}
      <button onClick={handleLogout}>Log Out</button>
    </div>
  );
};

export default UserDetail;
