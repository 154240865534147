import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions for Vighnaharta by AALLIANCE</h1>

      <p>
        Please read the following terms and conditions very carefully as your
        use of service is subject to your acceptance of and compliance with the
        following terms and conditions. By subscribing to or using any of our
        services, you agree that you have read, understood, and are bound by
        these Terms, regardless of how you subscribe to or use the services. If
        you do not want to be bound by the Terms, you must not subscribe to or
        use our services. In these Terms, references to “you”, “User” shall mean
        the end user accessing the website, its contents, and using the Services
        offered through the Website. “Service Providers” mean independent
        third-party service providers, and “we”, “us” and “our” shall mean
        AALLIANCE TSCS PRIVATE LIMITED.
      </p>

      <h2>About Us</h2>
      <p>
        We take orders online, collect payments, and deliver the products at the
        doorstep of the user. Any order placed by you is subject to product
        availability, delivery capacity, and acceptance by AALLIANCE TSCS
        PRIVATE LIMITED.
      </p>

      <h2>User Account, Password, and Security</h2>
      <p>
        If you use the website, you shall be responsible for maintaining the
        confidentiality of your display name and password and you shall be
        responsible for all activities that occur under your display name and
        password. You agree that if you provide any information that is untrue,
        inaccurate, not current, or incomplete or we have reasonable grounds to
        suspect that such information is untrue, inaccurate, not current, or
        incomplete, or not in accordance with these Terms of Use, we shall have
        the right to indefinitely suspend or terminate or block access to your
        login on the website and refuse to provide you with access to the
        Website. You agree to (a) immediately notify AALLIANCE TSCS PRIVATE
        LIMITED of any unauthorized use of your password or account or any other
        breach of security, and (b) ensure that you exit from your account at
        the end of each session.
      </p>

      <h2>Platform for Transaction and Communication</h2>
      <p>
        The website is a platform that users utilize to meet and interact with
        one another for their transactions. AALLIANCE TSCS PRIVATE LIMITED is
        not and cannot be a party to or control in any manner any transaction
        between the website’s users to browse through the variety of products
        available for sale. The transactions between AALLIANCE TSCS PRIVATE
        LIMITED and the users shall be kept confidential.
      </p>

      <h2>Compliance with Laws and Law Enforcement</h2>
      <p>
        The platform cooperates with mandated government and law enforcement
        agencies or any third parties by an order under the law for the time
        being in force to enforce and comply with the law. We will disclose any
        information about you to government or law enforcement officials or
        private parties as we, in our sole discretion, believe necessary or
        appropriate to respond to claims and legal processes, to protect the
        property and rights of the platform or a third party, to protect the
        safety of the public or any person, or to prevent or stop any illegal,
        unethical, or legally actionable activity. The platform may also provide
        your personal information to various tax authorities upon any demand or
        request from them.
      </p>

      <h2>Communication</h2>
      <p>
        We may send you details about new services and updates. If you do not
        wish to receive these communications, you can opt-out by sending an
        email to{" "}
        <a href="mailto:aalliancetscspvtltd@gmail.com">
          aalliancetscspvtltd@gmail.com
        </a>
        .
      </p>

      <h2>Business Transfers</h2>
      <p>
        The platform may sell, transfer, or otherwise share some or all of its
        assets, including your personal information, in connection with a
        merger, acquisition, reorganization, sale of assets, or in the event of
        bankruptcy.
      </p>

      <h2>Third Parties & Links</h2>
      <p>
        We may pass your details to other companies in our group. We may also
        pass your details to our agents and subcontractors to help us with any
        of our uses of your data set out in our Privacy Policy. For example, we
        may use third parties to assist us with delivering products to you, to
        help us collect payments from you, to analyze data, and to provide us
        with marketing or customer service assistance.
      </p>

      <h2>Cookies</h2>
      <p>
        The acceptance of cookies is not a requirement for visiting the site.
        However, we would like to point out that some functionality on the site
        and ordering are only possible with the activation of cookies. Cookies
        are tiny text files that identify your computer to our server as a
        unique user when you visit certain pages on the site and they are stored
        by your Internet browser on your computer’s hard drive. Cookies can be
        used to recognize your internet protocol address, saving you time while
        you are on or want to enter the site.
      </p>

      <h2>Security</h2>
      <p>
        The platform uses ordinary industry-standard technology designed to help
        keep your personal information safe. When we collect data through the
        site, we collect your personal details on a secure server. We use
        firewalls on our servers. When we collect payment card details
        electronically, we use encryption by using Secure Socket Layer (SSL)
        coding. The transmission of information over the Internet is not
        completely secure. Although we strive to protect your personal data, we
        cannot guarantee the security of your data while it is being transmitted
        to our site; any transmission is at your own risk. Once we have received
        your information, we have commercially reasonable procedures and
        security features in place to reasonably endeavor to prevent
        unauthorized access.
      </p>

      <h2>Copyright</h2>
      <p>
        From time to time we may update this Privacy Policy. Your continued
        subscription to our services constitutes an acceptance of the
        then-current Privacy Policy.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        the platform, or your dealings with the Website, please send a mail at{" "}
        <a href="mailto:aalliancetscspvtltd@gmail.com">
          aalliancetscspvtltd@gmail.com
        </a>{" "}
        or [contact number].
      </p>
    </div>
  );
};

export default TermsAndConditions;