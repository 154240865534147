import { jwtDecode } from "jwt-decode"; // Correctly import the named export
import axios from "axios";

export const isTokenExpired = (token) => {
  const decoded = jwtDecode(token); // Use the correct function name
  const currentTime = Date.now() / 1000; // current time in seconds
  return decoded.exp < currentTime;
};

export const setTokenExpirationTimeout = (token, onTokenExpire) => {
  const decoded = jwtDecode(token); // Use the correct function name
  const expirationTime = decoded.exp * 1000 - Date.now();
  setTimeout(() => {
    localStorage.removeItem("authToken");
    delete axios.defaults.headers.common["Authorization"];
    if (onTokenExpire) onTokenExpire();
  }, expirationTime);
};
