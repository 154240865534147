import React, { useEffect, useState } from "react";
import "./Cart.css";
import { useCart } from "../Context/CartContext";
import { useHistory } from "react-router-dom";

const Cart = () => {
  const { cart = [], removeItemFromCart, fetchCartByUserId, loading } = useCart();
  const history = useHistory();
  const userId = localStorage.getItem("userId");
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));

  useEffect(() => {
    if (!authToken || !userId) {
      history.push("/account");
      return;
    }
    fetchCartByUserId(userId);
  }, [authToken, userId, fetchCartByUserId, history]);

  if (loading) return <div>Loading...</div>;

  const handleCheckout = () => {
    history.push("/checkout");
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.priceAtTimeOfAddition * item.quantity, 0);
  };

  return (
    <div className="cart-container">
      <h2>BLESSINGS IN YOUR CART FROM LORD GANESHA</h2>
      {cart.length === 0 ? (
        <p className="empty-cart-message">Your cart is empty</p>
      ) : (
        <ul>
          {cart.map((item) => (
            <li key={item.id}>
              <img src={item.image} alt={item.name} />
              <div className="item-details">
                <h3>{item.name}</h3>
                <p>Price: ₹{item.priceAtTimeOfAddition}</p>
                <p>Quantity: {item.quantity}</p>
                <button
                  className="remove-button"
                  onClick={() => removeItemFromCart(item.item_id)}
                >
                  Remove
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="cart-actions">
        <span>Total: ₹{calculateTotal()}</span>
        <button onClick={handleCheckout} disabled={cart.length === 0}>
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default Cart;
