import React from "react";
import "./ShippingPolicy.css";

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy-container">
      <h1>Shipping Policy</h1>
      <h2>Shipping Policy for Vighnaharta by AALLIANCE</h2>

      <div className="shipping-policy-content">
        <h3>Introduction</h3>
        <p>
          This Shipping Policy ("Policy") outlines the shipping practices and
          procedures of AALLIANCE TSCS PVT. LTD. ("Company", "we", "our", or
          "us"). By making a purchase through the Vighnaharta by AALLIANCE website
          ("Website"), you agree to the terms of this Policy.
        </p>

        <h3>1. Shipping Areas</h3>
        <p>
          1.1 We currently ship to addresses within the states of Maharashtra and
          Goa, India.
        </p>

        <h3>2. Shipping Methods and Delivery Times</h3>
        <p>
          2.1 Shipping will be carried out by AALLIANCE TSCS PVT. LTD. We reserve
          the right to handle shipping in any manner we deem appropriate.
        </p>
        <p>
          2.2 Orders will typically be processed and shipped on the day chosen by the user during checkout, or as per the availability in case of a pickup of the item
          after the order confirmation. Delivery times are estimated and
          may be subject to delays due to unforeseen circumstances.
        </p>

        <h3>3. Shipping Charges</h3>
        <p>
          3.1 The cost of the product as shown at the time of purchase is final.
          For COD orders, the amount specified in the bill/receipt is final, and
          there will be no negotiation or dispute as the company has the final
          say.
        </p>

        <h3>4. Delivery Options</h3>
        <h4>4.1 Delivery to the Door:</h4>
        <ul>
          <li>
            This service involves delivery directly to your door. Once the product
            has been delivered and entered your door, it becomes your property.
            Please handle the idol with respect and care to avoid harming any
            religious sentiments.
          </li>
        </ul>
        <h4>4.2 Pickup from a Local Delivery Spot:</h4>
        <ul>
          <li>
            If you choose this option, an address will be designated as the pickup
            point for your order. The idol and any accompanying items will be
            handed over only upon presentation of the online receipt or a printed
            receipt. Without proof of ownership, the idol will not be released.
          </li>
          <li>
            In case of any changes to the pickup spot, customers will be notified
            via email or phone.
          </li>
        </ul>

        <h3>5. Payment Methods</h3>
        <p>5.1 We accept the following payment methods:</p>
        <ul>
          <li>Complete amount online.</li>
          <li>
            Half the amount online and the remaining half as Cash on Delivery
            (COD), payable either in cash or via QR code at the time of delivery.
          </li>
        </ul>

        <h3>6. Payment Obligations</h3>
        <p>
          6.1 If the remaining amount for a 50% COD order is not paid at the time
          of delivery, the company reserves the right to pursue legal action until
          the due amount is paid in full.
        </p>

        <h3>7. No Refunds</h3>
        <p>7.1 We do not offer refunds for any orders.</p>
        

        <h3>8. Delivery Date Selection</h3>
        <p>
          8.1 Customers can choose their preferred delivery date. If the selected
          date cannot be met, customers will be informed in advance, and we will
          work to accommodate the new schedule to the best of our ability.
        </p>

        <h3>9.Return Policy</h3>
        <p>9.1 We do not offer any returns on our idol products once they have been shipped to the Delivery location or Pickup Location. There will be no return accepted by AALLIANCE TSCS. </p>

        <h3>Charges</h3>
        <p>
          Prices for products are described on our website and are incorporated
          into these Terms by reference. All prices are quoted in Indian rupees.
          In particular, AALLIANCE TSCS PRIVATE LIMITED may at its sole discretion
          introduce new products/services and modify some or all of the existing
          products/services offered on the website. Changes to the fee policy
          shall be posted on the website and such changes shall automatically
          become effective immediately after they are posted on the website.
          AALLIANCE TSCS PRIVATE LIMITED reserves the right to change its fee
          policy from time to time.
        </p>

        <h3>Payment</h3>
        <p>
          While availing any of the payment methods available on the website, we
          will not be responsible or assume any liability, whatsoever in respect
          of any loss or damage arising directly or indirectly to you due to:
        </p>
        <ul>
          <li>Lack of authorization for any transaction/s, or</li>
          <li>
            Exceeding the preset limit mutually agreed by you and between
            “Bank/s”, or
          </li>
          <li>Any payment issues arising out of the transaction, or</li>
          <li>Decline of transaction for any other reason/s</li>
        </ul>
        <p>
          All payments made against the purchases/services on website by you shall
          be compulsorily in Indian Rupees acceptable in the Republic of India.
          Website will not facilitate transactions with respect to any other form
          of currency with respect to the purchases made on website.
        </p>
        <p>Further:</p>
        <ul>
          <li>
            Transactions, transaction price and all commercial terms such as
            delivery, dispatch of products and/or services are as per principal to
            principal bipartite contractual obligations between buyer and us and
            payment facility is merely used by the buyer and us to facilitate the
            completion of the transaction. Use of the payment facility shall not
            render AALLIANCE TSCS PRIVATE LIMITED liable or responsible for the
            non-delivery, non-receipt, non-payment, damage, breach of
            representations and warranties, non-provision of after-sales or
            warranty services or fraud as regards the products and /or services
            listed on the website.
          </li>
          <li>
            You have specifically authorized AALLIANCE TSCS PRIVATE LIMITED or its
            service providers to collect, process, facilitate and remit payments
            and/or the transaction price electronically or through cash on
            delivery to and from other users in respect of transactions through
            payment facility.
          </li>
          <li>
            You understand, accept and agree that the payment facility provided by
            AALLIANCE TSCS PRIVATE LIMITED is neither a banking nor financial
            service but is merely a facilitator providing an electronic, automated
            online electronic payment, receiving payment through cash on delivery,
            collection and remittance facility for the transactions on the
            AALLIANCE TSCS PRIVATE LIMITED website using the existing authorized
            banking infrastructure and credit card payment gateway networks.
            Further, by providing payment facility, AALLIANCE TSCS PRIVATE LIMITED
            is neither acting as trustees nor acting in a fiduciary capacity with
            respect to the transaction or the transaction price.
          </li>
          <li>
            AALLIANCE TSCS PRIVATE LIMITED reserves the right to impose limits on
            the number of transactions or transaction price which it may receive
            from an individual valid credit/debit/ cash card / valid bank account/
            and such other infrastructure or any other financial instrument
            directly or indirectly through payment aggregator or through any such
            facility authorized by Reserve Bank of India to provide enabling
            support facility for collection and remittance of payment or by an
            individual buyer during any time period, and reserves the right to
            refuse to process transactions exceeding such limit.
          </li>
          <li>
            AALLIANCE TSCS PRIVATE LIMITED reserves the right to refuse to process
            transactions by buyers with a prior history of questionable charges
            including without limitation breach of any agreements with AALLIANCE
            TSCS PRIVATE LIMITED or breach/violation of any law or any charges
            imposed by issuing bank or breach of any policy.
          </li>
          <li>
            AALLIANCE TSCS PRIVATE LIMITED may do such checks as it deems fit
            before approving the receipt of/buyers commitment to pay (for cash on
            delivery transactions) transaction price from the buyer for security
            or other reasons at the discretion of AALLIANCE TSCS PRIVATE LIMITED.
            As a result of such check if AALLIANCE TSCS PRIVATE LIMITED is not
            satisfied with the credibility of the buyer or genuineness of the
            transaction / transaction price, it will have the right to reject the
            receipt of / buyers’ commitment to pay transaction price.
          </li>
          <li>
            AALLIANCE TSCS PRIVATE LIMITED may delay notifying the payment
            confirmation i.e. informing to dispatch, if AALLIANCE TSCS PRIVATE
            LIMITED deems suspicious or for buyers conducting high transaction
            volumes to ensure safety of the transaction and transaction price. In
            addition, AALLIANCE TSCS PRIVATE LIMITED may hold transaction price
            and may not dispatch or remit transaction price to law enforcement
            officials (instead of refunding the same to buyer) at the request of
            law enforcement officials or in the event the buyer is engaged in any
            form of illegal activity.
          </li>
          <li>
            In the case of a cancellation that has occurred from the end of the
            client there will be no refund of the token amount that has been paid.
          </li>
          <li>
            The buyer acknowledges that AALLIANCE TSCS PRIVATE LIMITED will not be
            liable for any damages, interests or claims etc. resulting from not
            processing a transaction/transaction price or any delay in processing
            a transaction/transaction price which is beyond control of AALLIANCE
            TSCS PRIVATE LIMITED.
          </li>
        </ul>

        <h3>Trademark, Copyright and Restriction</h3>
        <p>
          This site is controlled and operated by AALLIANCE TSCS PRIVATE LIMITED.
          All material on this site, including images, illustrations, audio clips,
          and video clips, are protected by copyrights, trademarks, and other
          intellectual property rights. Material on website is solely for your
          personal, non-commercial use. You must not copy, reproduce, republish,
          upload, post, transmit or distribute such material in any way, including
          by email or other electronic means and whether directly or indirectly
          and you must not assist any other person to do so. Without the prior
          written consent of the owner, modification of the materials, use of the
          materials on any other website or networked computer environment or use
          of the materials for any purpose other than personal, non-commercial use
          is a violation of the copyrights, trademarks and other proprietary
          rights, and is prohibited. Any use for which you receive any
          remuneration, whether in money or otherwise, is a commercial use for the
          purposes of this clause.
        </p>

        <h3>Indemnity</h3>
        <p>
          You agree that you will indemnify and hold harmless AALLIANCE TSCS
          PRIVATE LIMITED and its directors, officers, shareholders, and agents
          from any or all liability arising in connection with your use of the
          Vighnaharta by AALLIANCE, including but not limited to any information
          you post on Vighnaharta by AALLIANCE.
        </p>

        <h3>Force Majeure</h3>
        <p>
          AALLIANCE TSCS PRIVATE LIMITED shall have no liability to you for any
          interruption or delay in access to the site irrespective of the cause.
        </p>

        <h3>Governing Law</h3>
        <p>
          These terms shall be governed by and constructed in accordance with the
          laws of India without reference to conflict of laws principles and
          disputes arising in relation hereto shall be subject to the exclusive
          jurisdiction of the courts at Maharashtra.
        </p>

        <h3>General Terms for Offers and Promotions</h3>
        <ul>
          <li>
            Only one credit note/gift coupon/coupon can be used per order unless
            otherwise stated.
          </li>
          <li>
            AALLIANCE TSCS PRIVATE LIMITED shall introduce various offers, schemes
            or contests at different times. The terms and conditions for such
            contests shall be separately available on the site.
          </li>
          <li>
            AALLIANCE TSCS PRIVATE LIMITED reserves the right to
            change/modify/add/delete any of the terms and conditions prevailing on
            the different promotional schemes it introduces in the market from
            time to time. AALLIANCE TSCS PRIVATE LIMITED, at its discretion, can
            withdraw a particular scheme from the website or market.
          </li>
        </ul>

        <h3>Disclaimer of Warranties/ Limitation of Liability</h3>
        <p>
          This website, all the materials and products (including but not limited
          to software) and services, included on or otherwise made available to
          you through this site are provided on “as is” and “as available” basis
          without any representation or warranties, express or implied except
          otherwise specified in writing. Without prejudice to the foregoing
          paragraph, AALLIANCE TSCS PRIVATE LIMITED does not warrant that:
        </p>
        <ul>
          <li>
            This website will be constantly available, or available at all; or
          </li>
          <li>
            The information on this website is complete, true, accurate or
            non-misleading.
          </li>
        </ul>
        <p>
          AALLIANCE TSCS PRIVATE LIMITED will not be liable to you in any way or
          in relation to the contents of, or use of, or otherwise in connection
          with, the website. AALLIANCE TSCS PRIVATE LIMITED does not warrant that
          this site; information, content, materials, product (including software)
          or services included on or otherwise made available to you through the
          website; their servers; or electronic communication sent from us are
          free of viruses or other harmful components. Nothing on the website
          constitutes, or is meant to constitute, advice of any kind. All the
          products sold on the website are governed by different state laws and if
          we are unable to deliver such products due to implications of different
          state laws, we will return or will give credit for the amount (if any)
          received in advance by us from the sale of such product that could not
          be delivered to you. You will be required to enter a valid phone number
          while placing an order on the website. By registering your phone number
          with us, you consent to be contacted by us via phone calls and/or SMS
          notifications, in case of any order or shipment or delivery-related
          updates.
        </p>

        <h3>Contact Information</h3>
        <p>
          If you have questions or concerns regarding these Terms & Conditions,
          please contact our support at aalliancetscspvtltd@gmail.com or +91-8691940004.
        </p>
      </div>
    </div>
  );
};

export default ShippingPolicy;
