import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <h3>USEFUL LINKS</h3>
        <ul>
          <li>
            <NavLink to="/about">About us</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact us</NavLink>
          </li>
          <li>
            <NavLink to="/privacy-policy">Terms & Conditions and Privacy Policy</NavLink>
          </li>
          <li>
            <NavLink to="/shipping-policy">Shipping Policy</NavLink>
          </li>
          <li>
            <NavLink to="/faq">FAQ</NavLink>
          </li>
        </ul>
      </div>
      <div className="footer-social">
        <ul>
          <li>
            <a
              href="https://www.instagram.com/aalliancetscs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/aalliance-tscs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/vighnahartacollectionbyaalliance"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-copy">
        <p>&copy; 2024 www.aalliancetscs.com | All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;