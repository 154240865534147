import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./OrderDetail.css"; 
const OrderDetail = () => {
  
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);  
  const [error, setError] = useState(null);
  const[loading,setLoading]=useState(null);
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`/api/v1/order/${orderId}`); 
        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }
        const data = await response.json();
        console.log(data);
        setOrderDetails(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) {
    return <div className="order-detail-container">Loading...</div>;
  }

  if (error) {
    return <div className="order-detail-container">Error: {error}</div>;
  }

  if (!orderDetails) {
    return null;
  }

  const {
    _id,
    created_at,
    status,
    items,
    total_price,
    shipping_id,
  } = orderDetails;

  const formattedDate = new Date(created_at).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="order-detail-container">
      <h2>Order #{_id}</h2>
      <p>
        Order <span className="highlight">#{_id}</span> was placed on{" "}
        <span className="highlight">{formattedDate}</span> and is currently{" "}
        <span className="highlight">{status}</span>.
      </p>
      <h3>Order details</h3>
      <table className="order-detail-table">
        <thead>
          <tr>
            <th>PRODUCT</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item._id}>
              <td>
                <div className="product-info">
                  <img
                    src={item.product_id.images[0]}
                    alt={item.product_id.name}
                    className="product-image"
                  />
                  <div>{item.product_id.name} &times; {item.quantity}</div>
                </div>
              </td>
              <td>₹{item.price}</td>
            </tr>
          ))}
          <tr>
            <td>Subtotal:</td>
            <td>₹{total_price}</td>
          </tr>
        </tbody>
      </table>
      <h3>Billing address</h3>
      {shipping_id && (
        <div className="billing-address">
          <p>{shipping_id.first_name} {shipping_id.last_name}</p>
          <p>{shipping_id.address.line1}, {shipping_id.address.city} {shipping_id.address.postal_code}</p>
          <p>{shipping_id.method}</p>
          <p>
            <i className="fas fa-phone"></i> {shipping_id.phone_number}
          </p>
          <p>
            <i className="fas fa-envelope"></i> {orderDetails.user_id.email}
          </p>
        </div>
      )}
    </div>
  );
};

export default OrderDetail;
