import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import idols from "../assets/idols.jpg";
import "./Products.css";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    filterProduct();
  }, [products, selectedSizes]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get("/api/v1/products");
      setProducts(response.data.data);
      setFilter(response.data.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const filterProduct = () => {
    let updatedList = products;
    if (selectedSizes.length > 0) {
      updatedList = updatedList.filter((product) =>
        selectedSizes.includes(product.size.toString())
      );
    }
    setFilter(updatedList);
  };

  const handleSizeChange = (size) => {
    setSelectedSizes((prev) =>
      prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
    );
  };

  const ShowProducts = () => {
    return (
      <div className="product-grid">
        {filter.map((product) => (
          <div className="col-md-4 mb-4" key={product._id}>
            <div className="card h-100 text-center p-4">
              <img
                src={product.images[0]}
                className="card-img-top"
                alt={product.name}
                height="250px"
              />
              <div className="card-body">
                <h5 className="card-title mb-0">{product.name}</h5>
                <p className="card-text lead fw-bold mt-2">₹{product.price}</p>
                <NavLink
                  to={{
                    pathname: `/products/${product._id}`,
                    state: { product },
                  }}
                  className="btn btn-outline-dark"
                >
                  Buy Now
                </NavLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container my-5 py-5">
      <div className="row">
        <div className="col-12 mb-3">
          <div className="hero-image-container">
            <img src={idols} alt="Idols" className="hero-image" />
          </div>
          <hr />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12 filter-container">
          <label htmlFor="sizeFilter" className="filter-label">Filter by Size:</label>
          <select
            id="sizeFilter"
            className="filter-select"
            onChange={(e) => handleSizeChange(e.target.value)}
          >
            <option value="">All Sizes</option>
            {[...new Set(products.map((product) => product.size.toString()))].map(
              (size) => (
                <option key={size} value={size}>{size} Inch</option>
              )
            )}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ShowProducts />
        </div>
      </div>
    </div>
  );
};

export default Products;
