import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useCart } from "../Context/CartContext";
import "./ProductDetails.css";

const ProductDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pincode, setPincode] = useState("");
  const [updatedPrice, setUpdatedPrice] = useState(null);
  const [quantity, setQuantity] = useState("1");
  const { addItemToCart } = useCart();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/v1/products/${id}`);
        setProduct(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching product:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = useCallback(() => {
    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    
    if (!token || !userId) {
      alert("Please log in to add items to the cart.");
      history.push("/account", { from: location.pathname });
      return;
    }
    if (!pincode) {
      alert("Please enter your Delivery Pincode before Adding to Cart");
      return;
    }
    if (product) {
      addItemToCart({
        productId: product._id,
        price: updatedPrice || product.price,
        quantity: parseInt(quantity, 10),
        userId, // Include userId in the cart item
      });
      alert(`${product.name} added to cart`);
      history.push("/cart"); // Redirect to cart page after adding item
    }
  }, [addItemToCart, history, location.pathname, pincode, product, quantity, updatedPrice]);

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const handlePincodeSubmit = async () => {
    if (pincode.length === 6) {
      try {
        const response = await axios.post(`/api/v1/pincodes/check-pincode/${id}`, { pincode: parseInt(pincode, 10) });
        const updatedProduct = response.data.data;
        if (updatedProduct) {
          setUpdatedPrice(updatedProduct.discountedPrice);
        } else {
          setUpdatedPrice(null);
        }
      } catch (err) {
        console.error("Error checking pincode:", err);
      }
    } else {
      alert("Please enter a valid 6-digit pincode.");
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value, 10) >= 1 && !isNaN(parseInt(value, 10)))) {
      setQuantity(value);
    }
  };

  const handleQuantityBlur = () => {
    if (quantity === "" || parseInt(quantity, 10) < 1) {
      setQuantity("1");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message || "An error occurred while fetching the product details"}</div>;

  return (
    <div className="product-details-container my-5">
      <div className="back-button" onClick={() => history.goBack()}>
        <i className="fas fa-arrow-left"></i> Back
      </div>
      {product && (
        <div className="details-box">
          <div className="row">
            <div className="col-md-6 text-center">
              <img
                src={product.images && product.images.length > 0 ? product.images[0] : "placeholder-image-url"}
                alt={product.name}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h1 className="product-title">{product.name}</h1>
              <input
                type="text"
                value={pincode}
                onChange={handlePincodeChange}
                placeholder="PLEASE ENTER YOUR PINCODE TO AVAIL DISCOUNTS"
                className="form-control my-3"
              />
              <button onClick={handlePincodeSubmit} className="btn btn-outline-dark mb-3">Submit Pincode</button>
              <p className="product-price">
                {updatedPrice ? (
                  <>
                    <span className="original-price">₹{product.price}</span>
                    <span className="ml-3 discounted-price">₹{updatedPrice}</span>
                  </>
                ) : (
                  `₹${product.price}`
                )}
              </p>
              <div className="product-details">
                <p><strong>Size:</strong> {product.size}</p>
                <p><strong>Category:</strong> {product.category}</p>
                <div className="quantity-control">
                  <label htmlFor="quantity">Quantity:</label>
                  <input
                    type="number"
                    id="quantity"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    className="form-control"
                    min="1"
                  />
                </div>
                <button className="btn btn-outline-dark btn-lg" onClick={handleAddToCart}>
                  Add to Cart
                </button>
              </div>
              <div className="product-description mt-4">
                <h4>Description</h4>
                <p className="description">{product.description}</p>
                <h4>Features</h4>
                <p className="features">{product.features}</p>
                <h4>Product Care</h4>
                <p className="product-care">{product.product_care}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;