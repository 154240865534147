// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ff0de;
  color: #fff;
  text-align: center;
}

.status-message {
  font-size: 2rem;
  font-weight: bold;
}

.success-message {
  color: #008000;
}

.failure-message {
  color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/components/CheckPaymentStatus.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,wBAAwB;EACxB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".payment-status-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #ff0de;\n  color: #fff;\n  text-align: center;\n}\n\n.status-message {\n  font-size: 2rem;\n  font-weight: bold;\n}\n\n.success-message {\n  color: #008000;\n}\n\n.failure-message {\n  color: #ff0000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
