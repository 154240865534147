import React, { useState } from "react";
import axios from "axios";
import ganeshjicontact from "../assets/ganeshjicontact.jpg";
import "./Contactus.css";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError("");
    setFormSuccess("");

    if (!name || !email || !phone || !message) {
      setFormError("All fields are required.");
      return;
    }

    try {
      const response = await axios.post("/api/v1/contact", {
        user_id: localStorage.getItem("userId"), // Replace with actual user ID if available
        name,
        email,
        phone_number: phone,
        message,
      });
      setFormSuccess("Your message has been sent successfully.");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.error("Error creating contact:", error);
      setFormError("There was an error sending your message. Please try again.");
    }
  };

  return (
    <div
      className="contact-us-container"
      style={{ backgroundImage: `url(${ganeshjicontact})` }}
    >
      <div className="contact-us">
        <div className="contact-form">
          <h2>SEND US AN EMAIL</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-col">
                <label>Your Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-col">
                <label>Your Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-row">
              <label>Your Message</label>
              <textarea
                name="message"
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            {formError && <p className="error-text">{formError}</p>}
            {formSuccess && <p className="success-text">{formSuccess}</p>}
            <div className="form-row">
              <button type="submit">SUBMIT</button>
            </div>
          </form>
        </div>
        <div className="divider"></div>
        <div className="contact-info">
          <h2>CONTACT US</h2>
          <div className="contact-info-row">
            <div className="contact-info-col">
              <p>
                <i className="fas fa-phone"></i> Customer Care +91-869190004
              </p>
              <p>
                <i className="fas fa-mobile-alt"></i> CS1 : +91-9773737385
              </p>
              <p>
                <i className="fas fa-mobile-alt"></i> CS2: +91-9167363716
              </p>
              <p>
                <i className="fas fa-envelope"></i> info@alliance.co.in
              </p>
            </div>
            <div className="contact-info-col">
              <p>
                <i className="fas fa-map-marker-alt"></i> Vighnaharta Collection
                <br />
                L/7, KBM COMPOUND, MILITARY ROAD
                <br />
                OPP. ASHOK NAGAR 
                <br />
                ANDHERI EAST, MUMBAI - 400059.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
