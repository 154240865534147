import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { setTokenExpirationTimeout } from "../utils/tokenUtils";
import "./Account.css";
import ganeshji from "../assets/ganeshji.jpg";

const Account = () => {
  const history = useHistory();
  const location = useLocation();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      let emailOrPhone = identifier;
      if (/^\d{10}$/.test(identifier)) {
        emailOrPhone = `91${identifier}`;
      }

      const response = await axios.post("/api/v1/user/login", {
        email: emailOrPhone,
        password,
      });

     
      const { token, userId } = response.data.data.token;

      // Log the token and userId to check their validity
      

      if (typeof token !== 'string') {
        throw new Error('Invalid token format');
      }

      localStorage.setItem("userId", userId);
      localStorage.setItem("authToken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      setTokenExpirationTimeout(token, () => {
        alert("Your session has expired. Please log in again.");
        history.push("/account");
      });

      alert("Login successful!");

      history.push("/user-details");
    } catch (err) {
      console.error("Error logging in:", err);
      setError("Invalid credentials. Please try again.");
    }
  };

  return (
    <div className="account-page">
      <div className="login-container">
        <div className="login-card">
          <img src={ganeshji} alt="Ganesh Ji" className="ganesh-img" />
          <h2>Welcome to AALLIANCE</h2>
          <p className="description">
            Take your first step towards bringing the blessings of Ganpati Ji
          </p>
          <form
            id="login-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <div className="input-container">
              <label htmlFor="identifier">Email</label>
              <input
                type="text"
                id="identifier"
                pattern="^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}|\d{10})$"
                title="Please enter a valid email address "
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                required
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                pattern=".{6,}"
                title="Password must be at least 6 characters long"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error-text">{error}</p>}
            <button type="submit" className="sign-in-button">
              Log In
            </button>
            
            <p className="request-text">
              Don’t have an Account?{" "}
              <NavLink to="/signup" className="sign-up-link">
                Sign Up
              </NavLink>
            </p>
          </form>

          <NavLink to="/" className="back-button">
            Back to Home
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Account;
