import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './CheckPaymentStatus.css';
import axios from 'axios';

const CheckPaymentStatus = () => {
    const { orderId } = useParams();
    const history = useHistory();
    const [status, setStatus] = useState('Checking Payment Status...');
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                const response = await fetch(`/api/v1/payment/status/${orderId}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                });
                const result = await response.json();
                if (result.success) {
                    if (result.data.status === 'CHARGED') {
                        setStatus('Payment Successful');
                        await clearCart(userId); // Clear the cart after successful payment
                        setTimeout(() => {
                            history.push(`/orders/${orderId}`);
                        }, 2000);
                    } else if (['FAILED', 'AUTHENTICATION_FAILED', 'AUTHORIZATION_FAILED'].includes(result.data.status)) {
                        setStatus('Payment Failed');
                        setTimeout(() => {
                            history.push('/checkout');
                        }, 2000);
                    } else {
                        setStatus('Payment Status Unknown');
                    }
                } else {
                    setStatus('Error Checking Payment Status');
                }
            } catch (error) {
                console.error('Error checking payment status:', error);
                setStatus('Error Checking Payment Status');
            }
        };

        const clearCart = async (userId) => {
            try {
                const response = await axios.put(`/api/v1/cart/clear/${userId}`);
                if (response.data.success) {
                    console.log('Cart cleared successfully');
                } else {
                    console.error('Failed to clear cart');
                }
            } catch (error) {
                console.error('Error clearing cart:', error);
            }
        };

        checkPaymentStatus();
    }, [orderId, userId, history]);

    return (
        <div className="payment-status-container">
            <h1 className="status-message">{status}</h1>
        </div>
    );
};

export default CheckPaymentStatus;
