// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-conditions {
    font-family: Arial, sans-serif;
    margin: 20px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .terms-and-conditions h1 {
    color: #333;
  }
  
  .terms-and-conditions h2 {
    color: #333;
    margin-top: 20px;
  }
  
  .terms-and-conditions ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .terms-and-conditions a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .terms-and-conditions a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/components/TermsAndConditions.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".terms-and-conditions {\n    font-family: Arial, sans-serif;\n    margin: 20px;\n    line-height: 1.6;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .terms-and-conditions h1 {\n    color: #333;\n  }\n  \n  .terms-and-conditions h2 {\n    color: #333;\n    margin-top: 20px;\n  }\n  \n  .terms-and-conditions ul {\n    list-style-type: disc;\n    margin-left: 20px;\n  }\n  \n  .terms-and-conditions a {\n    color: #1a73e8;\n    text-decoration: none;\n  }\n  \n  .terms-and-conditions a:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
