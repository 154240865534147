import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Products from "./components/Products";
import ProductDetails from "./components/ProductDetails";
import Home from "./components/Home";
import Account from "./components/Account";
import Signup from "./components/Signup";
import Contactus from "./components/Contactus";
import Aboutus from "./components/Aboutus";
import Orders from "./components/Orders";
import OrderDetail from "./components/OrderDetail";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import ShippingPolicy from "./components/ShippingPolicy";
import { CartProvider } from "./Context/CartContext";
import Cart from "./components/Cart";
import Popup from "./components/Popup";
import Checkout from "./components/Checkout";
import UserDetails from "./components/UserDetails";
import Faq from "./components/Faq";
import "./App.css";
import CheckPaymentStatus from "./components/CheckPaymentStatus";
import AllOrders from "./components/AllOrders"
function App() {
  return (
    <CartProvider>
      <Router>
        <Navbar />
        <Popup />
        
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/:id" component={ProductDetails} />
            <Route exact path="/account" component={Account} />
            <Route path="/signup" component={Signup} />
            <Route path="/user-details" component={UserDetails} />
            <Route exact path="/contact" component={Contactus} />
            <Route exact path="/about" component={Aboutus} />
            <Route exact path="/orders/:orderId" component={Orders} />
            <Route path="/order/:orderId" component={OrderDetail} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/shipping-policy" component={ShippingPolicy} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/faq" component={Faq} />
            <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout}/>
            <Route path="/checkpaymentstatus/:orderId" component={CheckPaymentStatus} />
            <Route path="/orders" component={AllOrders} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;
