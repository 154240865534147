import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Terms & Conditions for Vighnaharta by AALLIANCE</h1>

      <div className="privacy-policy-content">
        <p>
          At Vighnaharta by AALLIANCE, we value your trust and that’s why we
          insist upon the best standards for secure transactions and customer
          information privacy. As part of our Terms & Conditions, we would like to highlight our Privacy Policy. References to “you” mean any person submitting any
          data to us or the site.
        </p>

        <h2>Privacy Policy</h2>
        <p>
          The Personal Information which you may provide to us and/or which we may
          collect is or could be the following:
        </p>
        <ul>
          <li>
            On registering, you shall receive a unique password via email to
            access your Vighnaharta by AALLIANCE account. Post that, you can
            change the password if you wish. Note that we adopt reasonable
            security measures to protect your password from being exposed or
            disclosed to anyone, including the platform.
          </li>
          <li>
            Shipping, billing, tax registration, and other information pertaining
            to your sale or purchase transaction on the website.
          </li>
          <li>Your transaction details with other users of the website.</li>
          <li>If you use the website, you shall be responsible for maintaining the confidentiality of your display name and password and you shall be responsible for all activities that occur under your display name and password. You agree that if you provide any information that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms & Conditions, we shall have the right to indefinitely suspend or terminate or block access of your login on the website and refuse to provide you with access to the Website. You agree to immediately notify AALLIANCE TSCS pvt. ltd. of any unauthorized use of your password or account or any other breach of security.</li>
          <li>
            Details of the computer system or computer network which you use to
            visit the website and undertake any activity on the website.
          </li>
        </ul>

        <h2>Compliance with Laws and Law Enforcement</h2>
        <p>
          The platform cooperates with mandated government and law enforcement
          agencies or any third parties by an order under the law for the time
          being in force to enforce and comply with the law. We will disclose any
          information about you to government or law enforcement officials or
          private parties as we, in our sole discretion, believe necessary or
          appropriate to respond to claims and legal processes, to protect the
          property and rights of the platform or a third party, to protect the
          safety of the public or any person, or to prevent or stop any illegal,
          unethical, or legally actionable activity. The platform may also provide
          your personal information to various tax authorities upon any demand or
          request from them.
        </p>

        <h2>Communication</h2>
        <p>
          We may send you details about new services and updates. If you do not
          wish to receive these communications, you can opt-out by sending an
          email to{" "}
          <a href="mailto:aalliancetscspvtltd@gmail.com">
            aalliancetscspvtltd@gmail.com
          </a>{" "}
          or +91-869190004.
        </p>

        <h2>Business Transfers</h2>
        <p>
          The platform may sell, transfer, or otherwise share some or all of its
          assets, including your personal information, in connection with a
          merger, acquisition, reorganization, sale of assets, or in the event of
          bankruptcy.
        </p>

        <h2>Third Parties & Links</h2>
        <p>
          We may pass your details to other companies in our group. We may also
          pass your details to our agents and subcontractors to help us with any
          of our uses of your data set out in our Privacy Policy. For example, we
          may use third parties to assist us with delivering products to you, to
          help us collect payments from you, to analyze data, and to provide us
          with marketing or customer service assistance.
        </p>

        <h2>Cookies</h2>
        <p>
          The acceptance of cookies is not a requirement for visiting the site.
          However, we would like to point out that some functionality on the site
          and ordering are only possible with the activation of cookies. Cookies
          are tiny text files that identify your computer to our server as a
          unique user when you visit certain pages on the site and they are stored
          by your Internet browser on your computer’s hard drive. Cookies can be
          used to recognize your internet protocol address, saving you time while
          you are on or want to enter the site.
        </p>

        <h2>Security</h2>
        <p>
          The platform uses ordinary industry-standard technology designed to help
          keep your personal information safe. When we collect data through the
          site, we collect your personal details on a secure server. We use
          firewalls on our servers. When we collect payment card details
          electronically, we use encryption by using Secure Socket Layer (SSL)
          coding. The transmission of information over the Internet is not
          completely secure. Although we strive to protect your personal data, we
          cannot guarantee the security of your data while it is being transmitted
          to our site; any transmission is at your own risk. Once we have received
          your information, we have commercially reasonable procedures and
          security features in place to reasonably endeavor to prevent
          unauthorized access.
        </p>

        <h2>Copyright</h2>
        <p>
          From time to time we may update our Terms & Conditions. Your continued
          subscription to our services constitutes an acceptance of the
          then-current Terms & Conditions.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          the platform, or your dealings with the Website, please send a mail at{" "}
          <a href="mailto:aalliancetscspvtltd@gmail.com">
            aalliancetscspvtltd@gmail.com
          </a>{" "}
          or +91-869190004.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
