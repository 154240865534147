import React from "react";
import "./Faq.css";

const Faq = () => {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-grid">
        <div className="faq-item">
          <h2>How many delivery options are available, and how can I pay?</h2>
          <p>We offer two delivery methods for your convenience:</p>
          <ol>
            <li><b>Home Delivery:</b> Receive the blessings of Lord Ganesha right at your doorstep.</li>
            <li><b>Self-Pickup:</b> Personally bring Lord Ganesha to your home in a special way.</li>
          </ol>
          <p>For payment, you can either pay the full amount online (100%) or opt to pay 50% online and the remaining 50% upon receiving Lord Ganesha.</p>
        </div>
        <div className="faq-item">
          <h2>When will I receive my delivery?</h2>
          <p>You can receive your delivery between 25th August and 6th September. You can choose the delivery type during the billing and checkout process:</p>
          <ul>
            <li><b>Home Delivery:</b> Enjoy the blessings of Lord Ganesha at your home.</li>
            <li><b>Self-Pickup:</b> Collect your idol from the specified address provided in your order details.</li>
          </ul>
        </div>
        <div className="faq-item">
          <h2>Can I get a refund?</h2>
          <p>At this time, ALLIANCE TSCS does not offer refunds for order cancellations. However, if your idol is damaged, we will do our utmost to ensure you receive a replacement in the holiest manner.</p>
        </div>
        <div className="faq-item">
          <h2>What happens if my idol is received damaged?</h2>
          <p>We use the best packaging techniques to prevent any damage. However, if your idol is damaged upon arrival, we will replace it according to our policy, ensuring you receive the best possible service and the blessings of Lord Ganesha.</p>
        </div>
        <div className="faq-item">
          <h2>What should I do when I go to pick up the idol?</h2>
          <p>When you arrive to pick up your idol, please log in to your account and present your account name and a valid ID. Our staff will verify your order and hand over Lord Ganesha to you, ensuring a smooth and divine experience.</p>
        </div>
        <div className="faq-item">
          <h2>What if I like a product but there's no delivery available in my region?</h2>
          <p>If delivery is not available in your region, please contact us via the CONTACT US page. You can also send a request through any of the provided contact details. Our team will respond as soon as possible to assist you.</p>
        </div>
      </div>
      <p className="faq-note">These FAQs are designed to make your experience smooth, blessed, and filled with the joy of welcoming Lord Ganesha into your home.</p>
    </div>
  );
};

export default Faq;