import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Checkout.css';

const Checkout = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    address: '',
    apartment: '',
    city: '',
    state: 'Maharashtra',
    postalCode: '',
    country: 'India',
    homeDeliveryPostalCode: '',
    phone: '',
    shippingMethod: 'home-delivery',
    paymentMethod: 'pay-entire-online',
    orderNotes: '',
    gstNumber: '',
    businessName: '',
    deliveryDate: '',
    isBusiness: false,
  });
  const [pickupAddress, setPickupAddress] = useState('Default Pickup Center');
  const [orderDetails, setOrderDetails] = useState([]);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cartResponse = await fetch(`/api/v1/cart/${userId}`, {
          headers: {
            'authorization': `Bearer ${token}`,
          },
        });
        const cartData = await cartResponse.json();
        setOrderDetails(cartData.data.cart || []);
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };
    fetchData();
  }, [userId, token]);

  useEffect(() => {
    if (formData.shippingMethod === 'local-pickup' && formData.postalCode) {
      fetchPickupLocation(formData.postalCode);
    }
  }, [formData.shippingMethod, formData.postalCode]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handlePincodeChange = async (e) => {
    const { value } = e.target;
    setFormData({ ...formData, postalCode: value });
    if (value) {
      fetchPickupLocation(value);
    }
  };

  const fetchPickupLocation = async (pincode) => {
    try {
      const response = await fetch('/api/v1/pincodes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pincode }),
      });

      const data = await response.json();
      setPickupAddress(data.data || 'Default Pickup Center');
    } catch (error) {
      console.error('Error fetching pickup location:', error);
      setPickupAddress('Default Pickup Center');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const postalCode = formData.shippingMethod === 'home-delivery' ? formData.homeDeliveryPostalCode : formData.postalCode;

    if (!postalCode) {
      alert("Postal code is required");
      return;
    }
    const deliveryDate = formData.deliveryDate ? new Date(formData.deliveryDate).toISOString() : null;

    const paymentType = formData.paymentMethod === 'pay-partial' ? 'half' : 'full';
    const totalAmount = total;
    const paymentRemaining = formData.paymentMethod === 'pay-partial' ? total / 2 : 0;

    const payload = {
      user_id: userId,
      first_name: formData.firstName,
      last_name: formData.lastName,
      address: {
        line1: formData.address,
        line2: formData.apartment,
        city: formData.city,
        state: formData.state,
        postal_code: postalCode,
        country: 'India',
      },
      phone_number: formData.phone,
      method: formData.shippingMethod.replace('-', ' '),
      additional_info: formData.orderNotes,
      gst_percentage: 18,
      gst_number: formData.gstNumber,
      company_name: formData.businessName,
      delivery_date: deliveryDate,
      payment_type: paymentType,
    };

    try {
      const response = await fetch('/api/v1/shipping', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      
      const orderPayload = {
        user_id: userId,
        items: orderDetails.map(item => ({
          product_id: item.productId,
          product_name: item.name,
          quantity: item.quantity,
          price: item.priceAtTimeOfAddition
        })),
        total_price: totalAmount,
        status: 'Pending',
        shipping_id: result.data.newShippingInfo._id,
        payment_remaining: paymentRemaining,
        confirmed_deliver_date: formData.deliveryDate,
        delivery_date: formData.deliveryDate
      };

      console.log("Hitting API Orders", orderPayload);
      const orderResponse = await fetch('/api/v1/order', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(orderPayload),
      });

      const orderResult = await orderResponse.json();
      if (orderResult.success) {
        // Save order ID in local storage
        localStorage.setItem('orderId', orderResult.data._id);

        // Redirect to check payment status with order ID in params
        history.push(`/checkpaymentstatus/${orderResult.data._id}`);
        
        const paymentPayload = {
          order_id: orderResult.data._id,
          amount:formData.paymentMethod === 'pay-partial' ? paymentRemaining : totalAmount,
          payment_remaining: paymentRemaining,
          customer_id: userId,
          customer_email: formData.emailId, 
          customer_phone: formData.phone,
          payment_page_client_id: "hdfcmaster",
          action: "paymentPage",
          return_url: "https://aalliancetscs.com",
          description: "Order Payment",
          first_name: formData.firstName,
          last_name: formData.lastName,
          payment_type:formData.paymentMethod === 'pay-partial' ?  'partial' : 'full',
          pincode:postalCode,
          products:orderDetails.map(item=>({
            product_id:item.productId,
            quantity:item.quantity
          }))
          
        };
        console.log("Hitting API Payment", paymentPayload);
        const paymentResponse = await fetch('/api/v1/payment/create', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(paymentPayload),
        });

        const paymentResult = await paymentResponse.json();
        if (paymentResult.success) {
          // Redirect to the payment page
          window.location.href = paymentResult.data.payment_link;
        } else {
          console.error('Error creating payment:', paymentResult.message);
        }
      } else {
        console.error('Error creating order:', orderResult.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const availableDates = [
    'Dates','2024-08-26', '2024-08-27',
    '2024-08-28', '2024-08-29', '2024-08-30',
    '2024-08-31', '2024-09-01', '2024-09-02',
    '2024-09-03', '2024-09-04', '2024-09-05',
    '2024-09-06',
  ];

  const calculateSubtotal = () => {
    if (!orderDetails.length) return 0;
    return orderDetails.reduce((sum, item) => sum + item.priceAtTimeOfAddition * item.quantity, 0);
  };

  const calculateGST = (subtotal) => {
    return subtotal * 0;
  };

  const calculateTotal = (subtotal, gst) => {
    return subtotal + gst;
  };

  const subtotal = calculateSubtotal();
  const gst = calculateGST(subtotal);
  const total = calculateTotal(subtotal, gst);

  return (
    <div className="checkout-container">
      <h1>Lord Ganesha's Blessings Are Coming Your Way</h1>
      <div className="checkout-box">
        <div className="left-segment">
          <h2>Billing & Shipping</h2>
          <form id="checkout-form" onSubmit={handleFormSubmit}>
            <div className="input-container">
              <label htmlFor="firstName">First name <span className="required">*</span></label>
              <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
            </div>
            <div className="input-container">
              <label htmlFor="lastName">Last name <span className="required">*</span></label>
              <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
            </div>
            <div className="input-container">
              <label htmlFor="emailId">Email Id <span className="required">*</span></label>
              <input type="email" id="emailId" name="emailId" value={formData.emailId} onChange={handleInputChange} required />
            </div>
            <div className="input-container">
              <label>Country / Region <span className="required">*</span></label>
              <span id="country">India</span>
            </div>
            <div className="input-container business-checkbox-container">
              <input type="checkbox" id="isBusiness" name="isBusiness" checked={formData.isBusiness} onChange={handleInputChange} />
              <label htmlFor="isBusiness">Are you a Business? Login with your Business Details</label>
              {formData.isBusiness && (
                <div id="business-details" className="business-details-box">
                  <div className="input-container">
                    <label htmlFor="gstNumber">GST Number <span className="required">*</span></label>
                    <input type="text" id="gstNumber" name="gstNumber" value={formData.gstNumber} onChange={handleInputChange} pattern="[A-Za-z0-9]{15}" title="GST Number should be alphanumeric and 15 characters long" />
                  </div>
                  <div className="input-container">
                    <label htmlFor="businessName">Business Name <span className="required">*</span></label>
                    <input type="text" id="businessName" name="businessName" value={formData.businessName} onChange={handleInputChange} />
                  </div>
                  <div className="caution-box">
                    <small>If the GST Number is incorrect, the order will not be shared</small>
                  </div>
                </div>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="shippingMethod">Shipping Method <span className="required">*</span></label>
              <select id="shippingMethod" name="shippingMethod" value={formData.shippingMethod} onChange={handleInputChange} required>
                <option value="home-delivery">Home Delivery</option>
                <option value="local-pickup">Local Pickup</option>
              </select>
            </div>
            {formData.shippingMethod === 'home-delivery' && (
              <>
                <div className="input-container" id="delivery-date-container">
                  <label htmlFor="deliveryDate">Select Delivery Date <span className="required">*</span></label>
                  <select id="deliveryDate" name="deliveryDate" value={formData.deliveryDate} onChange={handleInputChange} required>
                    {availableDates.map((date, index) => (
                      <option key={index} value={date}>{new Date(date).toLocaleDateString('en-GB')}</option>
                    ))}
                  </select>
                </div>
                <div className="input-container" id="delivery-address-container">
                  <label htmlFor="address">Delivery Address <span className="required">*</span></label>
                  <input type="text" id="address" name="address" value={formData.address} onChange={handleInputChange} required />
                  <input type="text" id="apartment" name="apartment" value={formData.apartment} onChange={handleInputChange} placeholder="Apartment, suite, unit, etc. (optional)" style={{ marginTop: '10px' }} />
                </div>
                <div className="input-container">
                  <label htmlFor="homeDeliveryPostalCode">Pincode for Home Delivery <span className="required">*</span></label>
                  <input type="text" id="homeDeliveryPostalCode" name="homeDeliveryPostalCode" value={formData.homeDeliveryPostalCode} onChange={handleInputChange} required />
                </div>
              </>
            )}
            {formData.shippingMethod === 'local-pickup' && (
              <div id="pickup-location">
                <div className="input-container">
                  <label htmlFor="postalCode">Pincode <span className="required">*</span></label>
                  <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handlePincodeChange} required />
                </div>
                <p>Pickup Location: <span id="pickup-address">{pickupAddress}</span></p>
                <small>The pickup will be available at this location from 4th August to 6th August between 8am - 5pm and on 7th August before 12 pm</small>
              </div>
            )}
            <div className="input-container">
              <label htmlFor="state">State <span className="required">*</span></label>
              <select id="state" name="state" value={formData.state} onChange={handleInputChange} required>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Goa">Goa</option>
              </select>
            </div>
            <div className="input-container">
              <label htmlFor="city">City <span className="required">*</span></label>
              <input type="text" id="city" name="city" value={formData.city} onChange={handleInputChange} required />
            </div>
            <div className="input-container">
              <label htmlFor="phone">Phone <span className="required">*</span></label>
              <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} required />
            </div>
            <div className="input-container">
              <label htmlFor="paymentMethod">Payment Method <span className="required">*</span></label>
              <select id="paymentMethod" name="paymentMethod" value={formData.paymentMethod} onChange={handleInputChange} required>
                <option value="pay-entire-online">Pay Entire Online</option>
                <option value="pay-partial">Pay Partial (50% online & 50% COD)</option>
              </select>
            </div>
            
            <h2>Additional information</h2>
            <div className="input-container">
              <label htmlFor="orderNotes">Order notes (optional)</label>
              <textarea id="orderNotes" name="orderNotes" value={formData.orderNotes} onChange={handleInputChange} placeholder="Notes about your order, e.g., special notes for delivery"></textarea>
            </div>
            
          </form>
        </div>
        <div className="right-segment">
          <h2>Your order</h2>
          <div className="order-summary">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name} x{item.quantity}</td>
                    <td className="amount">₹{item.priceAtTimeOfAddition * item.quantity}</td>
                  </tr>
                ))}
                <tr>
                  <td>Subtotal</td>
                  <td className="amount">₹{subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>GST (0%)</td>
                  <td className="amount">₹{gst.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="total-label">Total</td>
                  <td className="amount total-amount">
                    {formData.paymentMethod === 'pay-partial' ? (
                      <>
                        Pay Online - ₹{(total / 2).toFixed(2)}<br />
                        Pay Cash on Delivery - ₹{(total / 2).toFixed(2)}
                      </>
                    ) : (
                      `₹${total.toFixed(2)}`
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="payment-options">
              <label>
                <input type="radio" name="payment" value="razorpay" defaultChecked />
                Pay by HDFC Bank
              </label>
              <p>Pay securely by Credit, Debit card, UPI or Internet Banking through HDFC Bank.</p>
            </div>
            <div className="terms">
              <label>
                <input type="checkbox" required />
                I have read and agree to the website <a href="https://aalliancetscs.com/shipping-policy">Shipping Policy</a> <span className="required">*</span>
              </label>
            </div>
            <button type="submit" className="confirm-button" form="checkout-form">
              Confirm Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;