import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    if (token && userId) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      fetchCartByUserId(userId);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchCartByUserId = useCallback(async (userId) => {
    try {
      if (!userId) {
        console.error("No userId found");
        return;
      }
      const response = await axios.get(`/api/v1/cart/${userId}`);
      setCart(response.data.data.cart);
    } catch (error) {
      console.error("Error fetching cart:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addItemToCart = async (item) => {
    try {
      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!token || !userId) {
        alert("Please log in to add items to the cart.");
        return;
      }
      await axios.post(`/api/v1/cart/add-item/${userId}/${item.productId}`, {
        quantity: item.quantity,
        price: item.price,
      });
      fetchCartByUserId(userId);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const removeItemFromCart = async (itemId) => {
    try {
      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!token || !userId) {
        alert("Please log in to remove items from the cart.");
        return;
      }
      
      await axios.delete(`/api/v1/cart/remove-item/${userId}/${itemId}`);
      fetchCartByUserId(userId);
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const clearCart = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!token || !userId) {
        alert("Please log in to clear the cart.");
        return;
      }
      await axios.delete(`/api/v1/cart/delete/${userId}`);
      setCart([]);
    } catch (error) {
      console.error("Error clearing cart:", error);
    }
  };

  return (
    <CartContext.Provider value={{ cart, addItemToCart, removeItemFromCart, clearCart, fetchCartByUserId, loading }}>
      {children}
    </CartContext.Provider>
  );
};
