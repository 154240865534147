import React, { useState } from "react";
import "./Popup.css";
import pop1 from "../assets/pop1.jpg"; 

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup-container">
      <div className="popup">
        <button className="close-btn" onClick={handleClose}>
          X
        </button>
        <div className="popup-content">
          <img src={pop1} alt="Popup" className="popup-image" />
        </div>
      </div>
    </div>
  );
};

export default Popup;