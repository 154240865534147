import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import "./Home.css";
import "./Products.css"; // Ensure you import Products.css to apply the styles

const Home = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get("/api/v1/products");
      setProducts(response.data.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <div>
      <div className="hero">
        <h1 className="hero-title">AALLIANCE</h1>
        <small className="hero-subtitle">presents</small>
        <h1 className="hero-title">THE VIGHNAHARTA COLLECTION</h1>
        <NavLink to="/products" className="button">
          Shop Now
        </NavLink>
      </div>
      <div className="products-section">
        <h2 className="section-title">OUR ECO-FRIENDLY IDOLS</h2>
        <p className="seo-paragraph">
          Experience the joy of bringing home Lord Ganesha with our eco-friendly idols, crafted with utmost care and devotion. We offer convenient home delivery, ensuring your idol arrives safely at your doorstep. Alternatively, you can choose to pick up your idol and bring Lord Ganesha home personally. Take advantage of our flexible payment options, where you can pay just 50% upfront and settle the remaining amount as cash on delivery. Enjoy amazing offers and benefit from 0%* charges on UPI payments. Celebrate with ease and peace of mind with our trusted service.
        </p>
        <div className="product-grid">
          {products.slice(0, 3).map((product) => (
            <div className="col-md-4 mb-4" key={product._id}>
              <div className="card h-100 text-center p-4">
                <img
                  src={product.images[0]}
                  className="card-img-top"
                  alt={product.name}
                />
                <div className="card-body">
                  <h5 className="card-title mb-0">{product.name}</h5>
                  <p className="card-text lead fw-bold">₹{product.price}</p>
                  <NavLink to={`/products/${product._id}`} className="btn btn-outline-dark">
                    Buy Now
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
